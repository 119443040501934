/* scrollbar */
@import 'simplebar-react/dist/simplebar.min.css';

/* lazy image */
@import 'react-lazy-load-image-component/src/effects/blur.css';


/* editor */
@import 'react-quill/dist/quill.snow.css';

/* carousel */
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

/* lightbox */
@import 'yet-another-react-lightbox/styles.css';
@import 'yet-another-react-lightbox/plugins/captions.css';
@import 'yet-another-react-lightbox/plugins/thumbnails.css';

@import 'leaflet/dist/leaflet.css';

@import 'swiper/css';
@import 'swiper/css/pagination';


/* Following lines disables weird background color autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px transparent inset !important;
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
}